@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn {
        @apply px-4 py-2 bg-blue-600 text-white rounded;
    }
}
html{
    height: 100%;
}

body {
  height: 100%;
  background-color: #f6f6f6;
  @apply font-Inter;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #f6f6f6 inset !important;
}

button:focus, input:focus, optgroup:focus, select:focus, textarea:focus{
  box-shadow: none !important;
}

.ui.image.evolve-logo{
  width: auto;
  max-width: 160px;
}

.mainContainer{
  padding-bottom: 30px;
  padding-top: 30px;
}

a.btnToggle{
  display: inline;
  margin-top: 20px;
  color: #000;
  text-align: left;
  padding-left: 20px;
  cursor: pointer;
}


.main-sidebar{
  background-color: white;
  display: flex;
  flex-direction: column;
  transition: all ease 0.2s !important;
}

.ui.container.verticalAlign{
  max-width: 500px !important;
  margin-top: auto;
  margin-bottom: auto;
}

#root{
  height: 100%;
}
.authWrapper{
  display: flex;
  height: 100%;
}

.logoWrapper{
  text-align: center;
  margin-bottom: 40px;
}
.logoWrapper img{
  margin-left: auto;
  margin-right: auto;
}
.spaceBetweenEl {
  justify-content: space-between;
  display: flex;
  width: 100%;
  padding-bottom: 16px;
}
.authBtn{
  font-size: 16px;
}

.login-img{
  max-height: 520px;
  border-radius: 12px;
  margin-bottom: 60px;
  max-width: 400px;
}

.custom-max-w{
  max-width: 120px;
  display: block;
}
.custom-max-h{
  overflow: auto;
  max-height: calc(100vh - 220px);
  min-height: 50px;
  height: 100%;
}
.custom-max-h-approvals{
  overflow: auto;
  max-height: calc(100vh - 140px);
  min-height: 50px;
  height: 100%;
}
.custom-payroll-change-min-h{
  overflow: auto;
  min-height: calc(100vh - 700px);
  height: 100%;
}
.custom-missing-revenue{
  max-height: 100vh;
  height: 100vh;
}
.custom-missing-revenue-content{
  max-height: calc(100vh - 280px);
}
.custom-unpaid-fuel{
  max-height: calc(100vh - 280px);
}
.custom-missing-trips{
  max-height: calc(100vh - 260px);
}
.custom-unpaid-dh{
  max-height: calc(100vh - 260px);
}
.max-avatar-content{
  max-width: 50px;
}
.sub-table-wrapper{
  max-width: calc(100vw - 384px);
  overflow-x: auto;
  transition: all ease 0.2s;
}
.sub-spot-table-wrapper{
  max-width: calc(100vw - 384px);
  overflow-x: auto;
  transition: all ease 0.2s;
}
.closedSidebar .sub-table-wrapper{
  max-width: calc(100vw - 180px);
  transition: all ease 0.2s;
}
.closedSidebar .sub-spot-table-wrapper{
  max-width: calc(100vw - 180px);
  transition: all ease 0.2s;
}
.custom-small-h{
  height: calc(100% - 120px);
}
.custom-small-h-approvals{
  height: calc(100% - 40px);
}

.custom-small-h-permissions-create{
  height: calc(100% - 130px);
}

.custom-small-h-permissions-edit{
  height: calc(100% - 85px);
}

.accordion label {
    cursor: pointer;
    user-select: none;
}
.accordion label:after {
    content: '+';
    position: absolute;
    right: 1em;
    color: #000;
    line-height: 20px;
    font-size: 20px;
    transition: all ease 0.2s;
}
.accordion input:checked + label:after {
    content: '-';
    transform: rotate(180deg);
    transition: all ease 0.2s;
    line-height: 20px;
    font-size: 20px;
}
.accordion__content{
    max-height: 0;
    transition: all 0.4s cubic-bezier(0.865, 0.14, 0.095, 0.87);
}
.accordion input[name='panel']:checked ~ .accordion__content {
    max-height: 100%;
}

.accordion label.no-arrow:after{
    display: none;
}
.accordion label.table-accordion-arrow{
    margin-top: -40px;
}
.accordion label.table-accordion-arrow:after{
    right: auto;
    left: 10px;
    top: -10px;
    height: 20px;
}
table tr.sub-table-level{
    border: none;
}
.alert-icon{
    cursor: pointer;
    height: 18px;
}
.main-container {
    /* give the outermost container a predefined size */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.main-section {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
}
.scrollable-content {
    background: white;
    flex-grow: 1;
    overflow: auto;
    min-height: 0;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.enable-container .check-wrapper{
    display: none
}
.enable-container label{
    user-select: none;
}
.enable-container label span{
    display: none;
}

.enable-container input[type="checkbox"]:checked + .check-wrapper {
    display: block
}
.filter-select{
    max-width: 200px;
}
.filter-select .ml-2:first-child{
    margin-left: 0;
}

main table tbody tr:last-child .ItemsNumber .custom-last-drop-item{
  bottom: 30px;
}
main table tbody tr:nth-last-child(2) .ItemsNumber .custom-last-drop-item{
  bottom: 30px;
}
.sidebar-logo{
  max-width: 80%;
  margin: auto;
}
.side-desk.w-20 .sidebar-logo{
  max-width: 100%;
}
.side-desk.w-20 .collapse-hide{
  display: none;
}
.side-desk.w-20 .custom-icon{
  padding-right: 0;
  padding-left: 0;

}
.side-desk.w-20 .custom-icon .custom-icon-item{
  width: 30px;
  height: 30px;
  margin-right: auto;
  margin-left: auto
}
.side-desk.w-20{
  transition: all ease 0.1s;
}
.side-desk.w-64{
  transition: all ease 0.1s;
}
.side-desk.w-20 .collapse-btn, .side-desk.w-20 .logout-wrapper{
  justify-content: center;
}
.side-desk.w-20 .collapse-btn{
  color: rgb(59, 178, 2);
}
.side-desk.w-20 .px-6.logo-wrapper{
  padding-left: 12px;
  padding-right: 12px;
  max-height: 56px;
}
.side-desk.w-64 .px-6.logo-wrapper{
  padding-left: 12px;
  padding-right: 12px;
  max-height: 200px;
}
.wBtnDrop.w-full{
  width: 160px;
}
.single-doc-item a{
  height: 100%;
  font-size: 22px;
  justify-content: center;
}
.single-doc-item a:hover{
  opacity: 0.7;
}
.single-doc-item a svg{
  width: 40px;
  height: 40px;
}
.cmin-h-60{
  min-height: 15em;
}

.minh-container{
  min-height: 500px;
}

.ql-editor{
  min-height: 200px;
}

.ql-editing{
  left: 0 !important;
}

.wysiwyg_html h1{
  display: block;
  font-weight: normal;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.wysiwyg_html h2{
  display: block;
  font-weight: normal;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.wysiwyg_html h3{
  display: block;
  font-weight: normal;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.wysiwyg_html ol{
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  padding-inline-start: 40px;
  list-style-type: decimal;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.wysiwyg_html ul{
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  padding-inline-start: 40px;
  list-style-type: disc;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.wysiwyg_html a{
  color: #0000EE;
  text-decoration: underline;
  cursor: pointer;
  text-decoration: underline;
  cursor: auto;
}

.rbc-row-segment:hover {
  z-index: 1000;
}

.rbc-event-content {
  overflow: visible !important;
}

.rbc-month-row {
  overflow: visible !important;
}

.rbc-row-content {
  z-index: auto !important;
}

.rbc-event {
  opacity: 1 !important;
}

.customSpinDoc {
  left: 47%;
  top: 40%;
}
.customSearch {
  margin-left: auto !important;
}

.button-bg-green {
  background-color: #DBF9CD;
}

.scrollbar::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}
.scrollbar::-webkit-scrollbar-track {
  background: white;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 40px;
  background-color: rgba(0, 0, 0, .16);
  border: 3px solid white;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, .28);
}

.scrollbar::-webkit-scrollbar-corner {
  background-color:  rgba(0, 0, 0, .35);
}

@media only screen and (max-width: 1023px) {
    .main-container{
        position: static;
    }
    .sub-table-wrapper, .sub-spot-table-wrapper{
      max-width: 100%;
    }
    .closedSidebar .sub-table-wrapper, .closedSidebar .sub-spot-table-wrapper{
      max-width: 100%;
    }
    .h-full.tabItemFull{
      height: auto;
    }
    .cmin-h-60{
      min-height: auto;
    }
    .custom-max-h, .custom-max-h-approvals{
      max-height: 100%;
    }
    .custom-missing-revenue{
      max-height: 700px;
    }
    .custom-missing-revenue-content{
      max-height: 450px;
    }
    .custom-unpaid-fuel{
      max-height: 475px;
    }
    .custom-missing-trips{
      max-height: 470px;
    }
    .custom-unpaid-dh{
      max-height: 470px;
    }
}
@media only screen and (max-width: 767px) {
  .tabs-buttons button{
    margin-left: 0 !important;
    padding-bottom: 5px !important;
    width: 50%;
    text-align: left;
  }
  .wBtnDrop.w-full{
    width: 100%;
  }
  .custom-missing-revenue-content{
    max-height: 390px;
  }
  .custom-missing-trips{
    max-height: 350px;
  }
  .custom-unpaid-fuel{
    max-height: 455px;
  }
  .custom-unpaid-dh{
    max-height: 455px;
  }
}
@media only screen and (max-width: 522px) {
  .custom-unpaid-dh{
    max-height: 415px;
  }
}
@media only screen and (max-width: 420px) {
  .filter-mobile{
    width: 100%;
    margin-left: 0;
  }
  .custom-missing-revenue-content{
    max-height: 315px;
  }
  .custom-unpaid-fuel{
    max-height: 400px;
  }
  .custom-missing-trips{
    max-height: 280px;
  }
}
@media only screen and (max-width: 375px) {
  .custom-missing-trips{
    max-height: 260px;
  }
  .custom-missing-revenue-content{
    max-height: 325spx;
  }
  .custom-unpaid-fuel{
    max-height: 350px;
  }
  .custom-unpaid-dh{
    max-height: 375px;
  }
}

.object {
  width: 20px;
  height: 20px;
  background-color: #3BB202;
  float: left;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  margin-right: 20px;
  margin-bottom: 20px;  
}

#loading-center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
  margin-top: -30px;
  margin-left: -30px;
  -webkit-animation: loading-center-absolute 1s infinite;
  animation: loading-center-absolute 1s infinite;
}

#object_one {
  -webkit-animation: object_one 1s infinite;
  animation: object_one 1s infinite;
}

#object_two {
  -webkit-animation: object_two 1s infinite;
  animation: object_two 1s infinite;
} 

#object_three {
  -webkit-animation: object_three 1s infinite;
  animation: object_three 1s infinite;
}  

#object_four {
  -webkit-animation: object_four 1s infinite;
  animation: object_four 1s infinite;
}   
  
@-webkit-keyframes loading-center-absolute{
100% {
    -ms-transform: rotate(360deg); 
    -webkit-transform: rotate(360deg); 
    transform: rotate(360deg); 
  }     
}  

@keyframes loading-center-absolute{
100% {
    -ms-transform: rotate(360deg); 
    -webkit-transform: rotate(360deg); 
    transform: rotate(360deg); 
  }
} 
  
@-webkit-keyframes object_one{
50% {
    -ms-transform: translate(20px,20px); 
    -webkit-transform: translate(20px,20px);
    transform: translate(20px,20px);
  }
}  

@keyframes object_one{
50% {
     -ms-transform: translate(20px,20px); 
    -webkit-transform: translate(20px,20px);
    transform: translate(20px,20px);
  }
}

@-webkit-keyframes object_two{
50% {
    -ms-transform: translate(-20px,20px); 
    -webkit-transform: translate(-20px,20px);
    transform: translate(-20px,20px);
  }
}  

@keyframes object_two{
50% {
    -ms-transform: translate(-20px,20px); 
    -webkit-transform: translate(-20px,20px);
    transform: translate(-20px,20px);
  }
}

@-webkit-keyframes object_three{
50% {
    -ms-transform: translate(20px,-20px); 
    -webkit-transform: translate(20px,-20px);
    transform: translate(20px,-20px);
  }
}   

@keyframes object_three{
50% {
    -ms-transform: translate(20px,-20px); 
    -webkit-transform: translate(20px,-20px);
    transform: translate(20px,-20px);
  }
}

@-webkit-keyframes object_four{
50% {
    -ms-transform: translate(-20px,-20px); 
    -webkit-transform: translate(-20px,-20px);
    transform: translate(-20px,-20px);
  }
}   
@keyframes object_four{
50% {
    -ms-transform: translate(-20px,-20px); 
    -webkit-transform: translate(-20px,-20px);
    transform: translate(-20px,-20px);
  }
}

.object:nth-child(2n+0) {
  margin-right: 0px;
}

.marker-cluster-custom {
  background-color: #DBF9CD;
  border: 3px solid white;
  border-radius: 20px;
}

.marker-cluster-custom-text {
  display: block;
  margin: auto;
  width: 50%;
  line-height: 34px;
  height: 34px;
  text-align: center;
}

.inline-date .react-datepicker-popper {
  z-index: 100;
  position: absolute;
  top: 0;
}

.inline-date .react-datepicker__triangle {
  left: -20px !important;
}